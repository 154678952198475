import React, { useState, useEffect } from "react"
import { css } from "@emotion/core"

import jordan1 from "../images/home/Jordan+Nassar+03.26.19-1.jpg"
import jordan2 from "../images/home/Jordan+Nassar+03.26.19-2.jpg"
import jordan4 from "../images/home/Jordan+Nassar+03.26.19-4.jpg"

const backgrounds = [jordan1, jordan2, jordan4]

const Background = () => {
  // TODO: switch from using indices to urls for checking which background to use
  const [bgIndex, setBgIndex] = useState(0)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setBgIndex(index => (index + 1 === backgrounds.length ? 0 : index + 1))
    }, 4000)

    return () => {
      clearTimeout(timeout)
    }
  })

  return (
    <div
      css={css`
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        z-index: -1;
      `}
    >
      {backgrounds.map(url => {
        return (
          <div
            key={url}
            css={css`
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0px;
              left: 0px;
              background: url(${url});
              background-size: cover;
              background-position: center;
              opacity: ${url === backgrounds[bgIndex] ? "1" : "0"};
              transition: opacity 1.5s ease-in-out;
            `}
          />
        )
      })}
    </div>
  )
}

export default Background
