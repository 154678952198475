import React from "react"
import { css } from "@emotion/core"
import { Link } from "gatsby"

import SEO from "../components/seo"
import Background from "../components/background"
import "../components/layout.css"

const IndexPage = () => (
  <div
    css={css`
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
    `}
  >
    <Background />
    <SEO title="Home" />
    <div
      css={css`
        text-align: center;
        width: 400px;
        min-width: 400px;

        a {
          text-decoration: none;
          text-transform: uppercase;
          font-family: inherit;
          font-size: 50px;
          letter-spacing: 0.5em;
          color: rgb(236, 234, 234);

          :hover {
            color: rgba(236, 234, 234, 0.6);
          }
        }
      `}
    >
      <Link to="/works">Jordan Nassar</Link>
    </div>
  </div>
)

export default IndexPage
